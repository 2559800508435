import React, { Component } from "react"
import Button from "./Button"

export default class ScrollToTop extends Component {

  container = {
    position: "fixed",
    bottom: "0.5rem",
    right: "0.5rem",
    animation: "fadeIn 700ms ease-in-out 1s both",
    cursor: "pointer",
  }

  constructor(props) {
    super(props)
    this.state = {
      is_visible: false,
    }
  }

  componentDidMount() {
    let scrollComponent = this
    document.addEventListener("scroll", function(e) {
      scrollComponent.toggleVisibility()
    })
  }

  isAtFooter() {
    return (window.innerHeight + window.scrollY) >= document.body.clientHeight - 100
  }

  toggleVisibility() {
    if (window.pageYOffset > 1200 && !this.isAtFooter()) {
      this.setState({
        is_visible: true,
      })
    } else {
      this.setState({
        is_visible: false,
      })
    }
  }

  scrollToTop() {
    let scrollDiv = document.getElementById("tags").offsetTop
    window.scrollTo({ top: scrollDiv, behavior: "smooth" })
  }

  render() {
    const { is_visible } = this.state
    return (
      <div style={this.container}>
        {is_visible && (
          <Button
            handleClick={() => this.scrollToTop()}
            label="Up"/>)
        }
      </div>
    )
  }
}
