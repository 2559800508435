import React from "react"

const section = {
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}

const Intro = () => {
  return (
    <section id="intro" style={section}>
      <article>
        <p>To my future child,</p>
        <p>Over the years I have collected a lot of advices and kept the ones that are quite useful. Here are some
          simple rules so you can navigate the boring stuff of life and enjoy the less boring parts of it.</p>
        <p>I hope that they will help you and that you may add some more to them, pass them
          along so people you care about will enjoy life with you, because a life in solitary and with no one to share
          what you know with, is a life not well lived.</p>
      </article>
    </section>
  )
}

export default Intro
