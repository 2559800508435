import React from "react"
import styles from "./tagFilter.module.css"
import ArticleTitle from "../ArticleTitle"

const TagFilter = (props) => {

  function getClass(selectedTag) {
    if (selectedTag === props.selectedTag)
      return styles.buttonActivated
    return styles.button
  }

  return (
    <section id="tags" className={styles.container}>
      <ArticleTitle text={"Categories"}/>
      <div className={styles.buttonGroup}>
        {props.filters.map((filterName) => {
          return <button
            className={getClass(filterName)}
            onClick={() => props.onChangeTag(filterName)}
          >{filterName}</button>
        })}
      </div>
    </section>
  )
}

export default TagFilter
