import React, { Component } from "react"
import SiteLayout from "../components/layouts/SiteLayout"
import SEO from "../components/SEO"
import Post from "../components/Post"
import Container from "../components/layouts/container/Container"
import Hero from "../components/hero/Hero"
import SeoImage from "../images/social-cover.jpg"
import Filters from "../components/tag_filter/TagFilter"
import { getAllUniqueTags, getDefaultTag, getFilteredList } from "../services/postService"
import ScrollToTop from "../components/ScrollToTop"
import Intro from "../components/Intro"

class IndexPage extends Component {

  state = {
    selectedTag: getDefaultTag(),
  }

  constructor(props) {
    super(props)
    this.onChangeTag = this.onChangeTag.bind(this)
  }

  onChangeTag(newTag) {
    this.setState({ selectedTag: newTag })
  }

  render() {
    return (
      <SiteLayout>
        <SEO
          title="Advice worth giving"
          description="Advice for everyone on everything, simple things I have picked up over the years"
          image={{ src: `${SeoImage}`, width: 1200, height: 627 }}/>
        <Hero/>
        <Container>
          <Intro/>
          <Filters filters={getAllUniqueTags()} selectedTag={this.state.selectedTag} onChangeTag={this.onChangeTag}/>
          <main id="book">
            <article>
              {getFilteredList(this.state.selectedTag).map((item) =>
                <Post key={item.id} post={item}/>)}
            </article>
          </main>
          <ScrollToTop/>
        </Container>
      </SiteLayout>
    )
  }
}

export default IndexPage
