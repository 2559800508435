import React from "react"

const buttonStyle = {
  textAlign: "left",
  padding: ".5rem .67rem",
  color: "white",
  fontFamily: "Libre Baskerville Regular serif",
  backgroundColor: "#191d27",
  fontSize: "1rem",
  border: ".1rem solid white",
}

const Button = (props) => {
  return (
    <button
      onClick={props.handleClick}
      style={buttonStyle}>
      {props.label}
    </button>
  )
}

export default Button
