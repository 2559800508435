import React from "react"
import styles from "./mainTitle.module.css"
import PropTypes from "prop-types"

const MainTitle = ({ text }) => {
  return (
    <div className={styles.text}>{text}</div>
  )
}

MainTitle.propTypes = {
  text: PropTypes.string.isRequired,
}

export default MainTitle
