import React from "react"
import styles from "./hero.module.css"
import MutedText from "../MutedText"
import MainTitle from "../main_title/MainTitle"
import Div100vh from "react-div-100vh"

const Hero = () => {
  return (
    <section>
      <Div100vh className={styles.heroSection}>
        <header className={styles.header}>
          <MainTitle text={"Advice worth giving"}/>
          <div className={styles.subTitle}>The best advice is this: <br/>Don’t take advice and don’t give advice.
            <br/><MutedText text={"Author Unknown"}/></div>
        </header>
        <div className={styles.buttonGroup}>
          <a className={`${styles.button} ${styles.buttonPrimary}`} href="#book">Start reading</a>
          <a className={styles.button} href="https://gumroad.com/renegens#TWxNO" target="_blank" rel="noopener noreferrer">Full Version</a>
        </div>
      </Div100vh>
    </section>
  )
}

export default Hero
